import { Injectable } from '@angular/core';
import { IUserInfo } from '../../models/IUserInfo';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { Observable } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { HttpService } from '../../shared/services/http.services';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import * as UserSessionActions from '../../shared/state/actions/user-session.actions';
import { UserState } from '../../shared/state/reducers/user-session.reducer';
import { User } from '../../models/user';
import { SharedServices } from '../../shared/services/shared.services';
import { apiConfigurationType } from '../admin/configuration/constants';

interface UserToken {
  firstname: string;
  lastname: string;
  uid: number;
  email: string;
  company: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn$: Observable<boolean>;

  public user$!: Observable<IUserInfo>;
  login: any;

  constructor(
    private _authService: PingAuthenticationService,
    private _httpService: HttpService,
    private _sharedServices: SharedServices,
    private _store: Store<{ user: UserState }>,
  ) {
    this.loggedIn$ = this._authService.identity$.pipe(map((l) => !!l));

    this._authService.identity$
      .pipe(
        skipWhile((value) => !value),
        take(1),
      )
      .subscribe((value) => {
        if (value.access_token) {
          sessionStorage.setItem('pingFederateToken', value.access_token);
          this.parseUserInfoFromPingFederateToken(value.access_token);
        }
      });
  }

  public createLoginRequest(user: User): any {
    const loginRequest: any = {
      businessUnit: user.businessUnit ? user.businessUnit : null,
      employeeLogin: user.employeeNumber,
      appSessionId: this._sharedServices.getAppSessionID(),
      siteMinderEmployeeId: user.employeeNumber,
      gets: apiConfigurationType.USERS,
      configFilters: {
        employeeNumber: user.employeeNumber,
      },
    };
    return loginRequest;
  }

  getUserConfigurationDetails(user: User): Observable<any> {
    const url = `${environment.apiBaseURL}/admin/EmployeeService/getEmployee`;
    return this._httpService.postService(url, this.createLoginRequest(user));
  }

  parseUserInfoFromPingFederateToken(accessToken: string): void {
    try {
      const id: UserToken = JSON.parse(atob(accessToken.split('.')[1]));
      const userInfo: User = {
        firstName: id.firstname,
        lastName: id.lastname,
        employeeNumber: Number.parseInt(String(id.uid), 10),
        email: id.email,
        airlineCode: id.company ? id.company.substring(0, 2) : '',
      };
      this._store.dispatch(UserSessionActions.loginAction({ userInfo }));
    } catch (err) {
      // Will redirect to Un Authorized page
    }
  }
}
